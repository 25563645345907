import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/v2/templates/Layout';
import Grid from '../../components/v2/atoms/Grid/Grid';
import useStoryblok from '../../lib/storyblok';
import Bloks from '../../components/atoms/Bloks';
import { Section } from '../../components/v2/templates/Section';
import IntroduceYourselfForm from '../../components/v2/molecules/Form/introduceYourself';
import SofaIllustration from '../../images/Sofa_illustration.svg';
import { StoryblokPage } from '../../types';

const GuarantidHome: StoryblokPage = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location);
  const { content } = story;

  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <Bloks bloks={content.SEO} />
      <Section curved hero noGrid curveColor="tertiary">
        <Bloks bloks={content.hero} />
      </Section>

      <Bloks bloks={content.sections.slice(0, content.sections.length - 2)} />

      <Section
        curved
        noGrid
        className="mb-32 lg:mb-44 bg-blue"
        curveColor="secondary"
        id="ApplyNow"
        topBox
      >
        <Grid className="relative pb-[270px] sm:pb-[290px] lg:pb-0">
          <IntroduceYourselfForm
            className="sm:max-w-none w-full col-span-4 sm:col-span-8 lg:col-start-2 lg:col-end-7 mt-11 lg:shadow-2xl lg:mt-25 lg:mb-9"
            queryParams={location.search}
            analyticsInfo="marketing - tenant guarantid"
          />
          <img
            src={SofaIllustration}
            alt="sofa with flying papers from the seat"
            className="absolute -bottom-20  left-3.5 w-[375px] sm:w-[343px] lg:w-[550px] xl:w-[700px] sm:right-5 sm:left-auto sm:-bottom-16"
            loading="lazy"
          />
        </Grid>
      </Section>

      <Bloks bloks={content.sections.slice(-2)} />
    </Layout>
  );
};

export default GuarantidHome;

export const query = graphql`
  query TenantGuarantee {
    storyblokEntry(full_slug: { eq: "tenant/guarantid" }) {
      content
      name
    }
  }
`;
